// JS for site-wide Product Configuration Widget
const productConfigWidgetFull = document.querySelectorAll('.js-product-config');
const jsToggleDonationAmountsControl = document.querySelectorAll('.js-toggle-donation-amounts-control');

// Hide / show different monthly/one-off donation amounts
if (jsToggleDonationAmountsControl != null) {
	let n;
	for (n = 0; n < jsToggleDonationAmountsControl.length; n++) {
		const jsToggleDonationAmountswrapper = jsToggleDonationAmountsControl[n];
		const controlTabs = jsToggleDonationAmountsControl[n].querySelectorAll('input[type="radio"]');

		function addActivePaymentMethod(activeTabId, targetElem) {
			if (activeTabId.includes('Monthly')) {
				const activeButton = targetElem?.querySelector('.payment-frequency-one-off-container.active');
				const targetButton = targetElem?.querySelector('.payment-frequency-monthly-container');
				if (activeButton) {
					activeButton.classList.remove('active');
				}
				if (targetButton) {
					targetButton.classList.add('active');
				}
			} else {
				const activeButton = targetElem?.querySelector('.payment-frequency-monthly-container.active');
				const targetButton = targetElem?.querySelector('.payment-frequency-one-off-container');
				if (activeButton) {
					activeButton.classList.remove('active');
				}
				if (targetButton) {
					targetButton.classList.add('active');
				}
			}
		}

		const showHideDonationAmounts = (thiselement) => {
			const activeTab = thiselement.querySelector('input[type="radio"]:checked');
			const controlSetsWrapper = thiselement.closest('.productconfig-wrapper');
			const controlSets = controlSetsWrapper.querySelectorAll('.js-toggle-donation-amounts-set');
			const activeTabControl = activeTab.id;
			let i;
			for (i = 0; i < controlSets.length; i++) {
				controlSets[i].classList.add('js-hidden');
			}
			const activeDonationAmountsSet = controlSetsWrapper.querySelector("[data-control='" + activeTabControl + "']");
			activeDonationAmountsSet?.classList?.remove('js-hidden');
			const activeDonationAmount = activeTab?.dataset?.defaultAmount;
			document.getElementById('MinDonationAmount').value = activeTab?.dataset?.minAmount;
			// Check if URL contains query string parameters as this overrides default donation amount
			const queryString = window.location.search;
			let activeDonationAmountInput = null;
			if (queryString.includes('donationAmount')) {
				const urlParams = new URLSearchParams(queryString);
				const donationAmount = urlParams.get('donationAmount');
				// Set donation amount to be amount shown in URL, or if blank show default amount
				if (donationAmount?.length > 0) {
					activeDonationAmountInput = document.querySelector("input[value='" + donationAmount + "']");
				} else {
					activeDonationAmountInput = activeDonationAmountsSet.querySelector("input[value='" + activeDonationAmount + "']");
				}
			} else { // If no donation amount in URL, select donation amount using cookies
				const selectedValue = activeDonationAmountsSet.querySelector("input[value='" + activeDonationAmount + "']");
				if (selectedValue != null) {
					activeDonationAmountInput = activeDonationAmountsSet.querySelector("input[value='" + activeDonationAmount + "']");
				} else {
					// Get cookie value for donation amount
					const donationAmountCookie = document.cookie.match(new RegExp('(^| )' + 'wv_donationAmount' + '=([^;]+)'));
					activeDonationAmountInput = activeDonationAmountsSet.querySelector("input[value='" + donationAmountCookie[2] + "']");
				}
			}

			// Default behaviour: use default donation amount and frequency set in Umbraco
			const activeDonationAmountText = activeDonationAmountInput.dataset.amountText;
			const activeTabChipText = activeDonationAmountsSet.querySelector('.chip-text');

			activeTabChipText.style.visibility = 'visible';
			activeTabChipText.innerText = activeDonationAmountText;

			const manualInputBox = controlSetsWrapper?.querySelector('.chip-set-payment-container .js-product-config-manual');
			if (manualInputBox) {
				manualInputBox.placeholder = activeDonationAmount;
			}

			const selectedPaymentInput = activeDonationAmountsSet.querySelector('input:checked');
			if (selectedPaymentInput) {
				manualInputBox.value = selectedPaymentInput.value;
			} else {
				manualInputBox.value = activeDonationAmount;
			}

			addActivePaymentMethod(activeTabControl, thiselement);

			// Changing input label dynamically based on the selected frequency
			const markupLabel = controlSetsWrapper?.querySelector('.js-add-error-markup #label-id9');
			let inputText = '';
			if (activeTabControl.includes('Monthly')) {
				inputText = 'Monthly';
			} else {
				inputText = 'One-Off';
			}

			if (markupLabel) {
				markupLabel.innerText = inputText ? inputText + ' amount' : 'Amount';
			}

			// Invoking a ResizeObserver to watch for size changes
			const targetContainers = thiselement?.querySelectorAll('.payment-frequency-monthly-container, .payment-frequency-one-off-container');
			if ((targetContainers.length > 0) && window.ResizeObserver) {
				let activeCurrentFrequency = '';
				if (activeTabControl.includes('Monthly')) {
					activeCurrentFrequency = 'Monthly';
				} else {
					activeCurrentFrequency = 'Oneoff';
				}
				let isMarginApplied = true;

				const resizeObserver = new window.ResizeObserver((entries) => {
					const hasSingleButton = thiselement?.querySelectorAll('.payment-frequency-monthly-container, .payment-frequency-one-off-container');
					const initialElem = hasSingleButton?.[0];
					const chipsetPaymentContainer = thiselement.closest('.transaction-form').querySelector('.chip-set-payment-container');
					const chipsetPaymentContainerRect = chipsetPaymentContainer?.getBoundingClientRect();
					if (hasSingleButton?.length && hasSingleButton?.length === 1) {
						const targetElem = initialElem;
						targetElem?.classList?.add('single_btn');
						const targetElemClientX = targetElem.getBoundingClientRect();
						const targetElemWidth = (targetElemClientX?.width);
						targetElem.style.setProperty('--translateOneAfterX', `${(`${targetElemWidth - 16}`.includes('.75') ? Math.floor(targetElemWidth - 16) : (targetElemWidth - 16))}px`);

						const chipsetContainer = document.querySelector('.chip-set-container.js-toggle-donation-amounts-control');
						const chipsetContainerWidth = chipsetContainer?.getBoundingClientRect()?.width;
						if (chipsetContainerWidth) {
							const width = parseFloat(chipsetContainerWidth);
							if (isNaN(width) === false) {
								const adjustedWidth = width.toString().includes('.5') ? width : width + 0.5;
								const beforeXWidth = adjustedWidth - 35;
								targetElem.style.setProperty('--translateBeforeX', `${beforeXWidth}px`);
							} else {
								const chipSetPaymentContainerWidth = chipsetPaymentContainer?.getBoundingClientRect()?.width;
								targetElem.style.setProperty('--translateBeforeX', `${chipSetPaymentContainerWidth - 34}px`);
							}
						}
						targetElem.style.setProperty('--translateOneAfterWidth', `${targetElemWidth - 18}px`);
						const siblingElement = thiselement.closest('.transaction-form').querySelector('.chip-set-payment-container')?.getBoundingClientRect();
						const targetHeight = targetElem?.getBoundingClientRect()?.y;
						const yAxisCoords = ((siblingElement?.y) - (targetHeight) - 14);
						targetElem.style.setProperty('--translateY', `${yAxisCoords}px`);
					} else if (initialElem?.classList?.contains('payment-frequency-one-off-container')) {
						const monthlyContainer = hasSingleButton?.[1];
						initialElem?.classList?.add('collapsed-design');
						monthlyContainer?.classList?.add('collapsed-design');

						const oneOffContainer = thiselement.querySelector('.payment-frequency-one-off-container');
						const oneOffClientRect = oneOffContainer?.getBoundingClientRect();
						const siblingElement = thiselement?.closest('.transaction-form')?.querySelector('.chip-set-payment-container')?.getBoundingClientRect();
						const targetHeight = monthlyContainer?.getBoundingClientRect();
						const monthlyContainerHeight = targetHeight?.y;
						const yAxisCoords = ((siblingElement?.y) - (monthlyContainerHeight) - 16);
						const targetSpanElement = thiselement.querySelector('.payment-frequency-monthly-container span');
						const targetSpanClientRect = targetSpanElement.getBoundingClientRect();

						const docWidth = document.querySelector('header').getBoundingClientRect().width;

						// Monthly
						monthlyContainer.style.setProperty('--translateX', `${-20}px`);
						monthlyContainer.style.setProperty('--translateMonthlyAfterY', `${oneOffClientRect?.height > 85 ? 12 : (-20)}px`);
						monthlyContainer.style.setProperty('--translateY', `${yAxisCoords}px`);
						const monthlyBeforeX = `${(-((oneOffClientRect?.width)) - 14)}px`;
						monthlyContainer.style.setProperty('--translateMonthlyBeforeX', monthlyBeforeX);

						// One off Payment frequency
						const targetSpanElemClient = thiselement.querySelector('.payment-frequency-one-off-container span').getBoundingClientRect();
						const oneOffYCoords = (oneOffClientRect?.height) - 63;
						oneOffContainer.style.setProperty('--translateOneOffY', `${oneOffYCoords}px`);
						oneOffContainer.style.setProperty('--translateOneOffX', `${(`${targetSpanClientRect?.width}`.includes('.75') ? Math.floor(targetSpanClientRect?.width) : targetSpanClientRect?.width) + 4}px`);
						if (docWidth > 720) {
							oneOffContainer.style.setProperty('--translateOneOffRightX', `${(`${siblingElement?.width}`.includes('.5') ? Math.floor(siblingElement?.width) : (siblingElement?.width)) - 23}px`);
						} else {
							oneOffContainer.style.setProperty('--translateOneOffRightX', `${(siblingElement?.width) - 23}px`);
						}
						const oneOffClientY = (oneOffClientRect.height) - (targetSpanElemClient?.height) - 16;
						oneOffContainer.style.setProperty('--translateOneOffRightY', `${Math.floor(oneOffClientY)}px`);
					} else {
						const siblingElement = thiselement.closest('.transaction-form').querySelector('.chip-set-payment-container')?.getBoundingClientRect();
						const monthlyContainer = thiselement.querySelector('.payment-frequency-monthly-container');
						const targetHeight = monthlyContainer?.getBoundingClientRect()?.y;
						const yAxisCoords = ((siblingElement?.y) - (targetHeight) - 13);
						const targetSpanElement = thiselement.querySelector('.payment-frequency-monthly-container span');
						const targetSpanClientRect = targetSpanElement.getBoundingClientRect();
						monthlyContainer.style.setProperty('--translateX', `${(targetSpanClientRect?.width) + 3}px`);
						monthlyContainer.style.setProperty('--translateY', `${yAxisCoords}px`);

						// One off Payment frequency
						const targetSpanElemClient = thiselement.querySelector('.payment-frequency-one-off-container span').getBoundingClientRect();
						const oneOffContainer = thiselement.querySelector('.payment-frequency-one-off-container');
						const oneOffClientRect = oneOffContainer?.getBoundingClientRect();
						const oneOffYCoords = (oneOffClientRect?.height) - 60;

						oneOffContainer.style.setProperty('--translateOneOffY', `${oneOffYCoords}px`);
						oneOffContainer.style.setProperty('--translateOneOffRightX', `${(((siblingElement?.width) / 2) - 20)}px`);
						const oneOffClientY = (oneOffClientRect.height) - (targetSpanElemClient?.height) - 13;
						oneOffContainer.style.setProperty('--translateOneOffRightY', `${Math.floor(oneOffClientY > 0 ? oneOffClientY : 0)}px`);
					}

					const footerAffiliates = chipsetPaymentContainer?.querySelector('.form-footer .form-footer__affiliates');
					const footerAffiliatesLink = chipsetPaymentContainer?.querySelectorAll('.form-footer .form-footer__affiliates .form-footer__affiliate-link');
					const requiredWidth = (footerAffiliatesLink.length < 5) ? 360 : 380;
					if (chipsetPaymentContainerRect?.width < requiredWidth) {
						footerAffiliates.style.cssText = 'flex-direction: column;';
						footerAffiliatesLink?.forEach((elem) => {
							if (elem?.classList?.contains('Monthly') || elem?.classList?.contains('Oneoff')) {
								if (elem?.classList?.contains(`${activeCurrentFrequency}`)) {
									elem.style.cssText = 'padding: 0.5rem 0;';
								} else {
									elem.style.cssText = 'display: none;';
								}
							} else {
								elem.style.cssText = 'padding: 0.5rem 0;';
							}
						});
					} else {
						footerAffiliates.style.cssText = 'flex-direction: row;';
						footerAffiliatesLink?.forEach((elem) => {
							if (elem?.classList?.contains('Monthly') || elem?.classList?.contains('Oneoff')) {
								if (elem?.classList?.contains(`${activeCurrentFrequency}`)) {
									elem.style.cssText = 'padding:  0 0.5rem;';
								} else {
									elem.style.cssText = 'display: none;';
								}
							} else {
								elem.style.cssText = 'padding:  0 0.5rem;';
							}
						});
					}

					const markupLabelHeight = markupLabel?.getBoundingClientRect()?.height;
					const submitButton = chipsetPaymentContainer?.querySelector('#js-submit');
					const computedStyle = window.getComputedStyle(submitButton);
					if (markupLabelHeight > 24) {
						const buttonMarginTop = computedStyle.marginTop;

						if (buttonMarginTop !== '0px') {
							isMarginApplied = true;
							submitButton.style.marginTop = (markupLabelHeight < 60) ? '3.7rem' : '5.1rem';
						}
					} else {
						const buttonMarginTop = computedStyle.marginTop;
						if (isMarginApplied && buttonMarginTop !== '0px') {
							submitButton.style.marginTop = '2.1rem';
						} else if (buttonMarginTop) {
							submitButton.style.marginTop = '2.1rem';
						}
					}
				});
				resizeObserver?.observe(targetContainers?.[0]);
			}
		};
		showHideDonationAmounts(jsToggleDonationAmountswrapper);

		let i;
		for (i = 0; i < controlTabs.length; i++) {
			const thisControl = controlTabs[i];
			thisControl.addEventListener('click', function(event) {
				const thisElement = event.target;
				const targetId = thisElement.id;
				// Changing active payment frequency dynamically when clicking on the button.
				const parentForm = thisElement.closest('.transaction-form');
				addActivePaymentMethod(targetId, parentForm);
				controlTabs.forEach(tab => tab.classList.remove('checked'));
				if (thisElement.checked) {
					thisElement.classList.add('checked');
				}
				showHideDonationAmounts(jsToggleDonationAmountswrapper);
			});
		}
	}
}

if (productConfigWidgetFull != null) {
	let k;
	for (k = 0; k < productConfigWidgetFull.length; k++) {
		let captionInput = productConfigWidgetFull[k].querySelectorAll('.chip-set-container:not(.js-hidden) .chip-input');
		const productConfigCaptionChips = productConfigWidgetFull[k].querySelectorAll('.chip-set--caption .radio-button--chip');
		const donationAmountInputs = productConfigWidgetFull[k].querySelectorAll('.js-toggle-donation-amounts-control input[type="radio"]');
		const productConfigInput = productConfigWidgetFull[k].querySelector('.js-product-config-manual');
		let captionInputValues = [];
		const productConfigForm = productConfigWidgetFull[k].closest('.transaction-form');
		const childIDInput = productConfigWidgetFull[k].querySelector('.js-child-id-validate');

		const formFooter = productConfigWidgetFull[k].querySelector('.form-footer');
		const tempFooter = productConfigWidgetFull[k].querySelector('.chip-set-payment-container .replaceable-form-footer');
		if (formFooter != null) {
			const tempDiv = document.createElement('div');
			tempDiv.appendChild(formFooter.cloneNode(true));
			if (tempFooter != null) {
				tempFooter.outerHTML = tempDiv.innerHTML;
				tempDiv.remove(); // Prevention for memory leak
				formFooter.remove();
			}
		} else {
			tempFooter?.remove();
		}

		// Adding border top if buttons are not exist
		const chipsetContainer = productConfigWidgetFull[k].querySelector('.chip-set-container.js-toggle-donation-amounts-control');
		if (chipsetContainer === null || chipsetContainer === undefined) {
			const chipsetPaymentContainer = productConfigWidgetFull[k].querySelector('.chip-set-payment-container');
			chipsetPaymentContainer.style.cssText = 'border-top: 3px solid #0C6699; border-radius: 10px;';

			if ((chipsetPaymentContainer) && window.ResizeObserver) {
				let isMarginApplied = true;
				const resizeObserver = new window.ResizeObserver((entries) => {
					const footerAffiliates = chipsetPaymentContainer?.querySelector('.form-footer .form-footer__affiliates');
					const footerAffiliatesLink = chipsetPaymentContainer?.querySelectorAll('.form-footer .form-footer__affiliates .form-footer__affiliate-link');
					const chipsetPaymentContainerRect = chipsetPaymentContainer?.getBoundingClientRect();
					const requiredWidth = (footerAffiliatesLink.length < 5) ? 360 : 380;
					if (chipsetPaymentContainerRect?.width < requiredWidth) {
						footerAffiliates.style.cssText = 'flex-direction: column;';
						footerAffiliatesLink?.forEach((elem) => {
							if (elem?.classList?.contains('Monthly') || elem?.classList?.contains('Oneoff')) {
								elem.style.cssText = 'padding: 0.5rem 0;';
							} else {
								elem.style.cssText = 'padding: 0.5rem 0;';
							}
						});
					} else {
						footerAffiliates.style.cssText = 'flex-direction: row;';
						footerAffiliatesLink?.forEach((elem) => {
							if (elem?.classList?.contains('Monthly') || elem?.classList?.contains('Oneoff')) {
								elem.style.cssText = 'padding:  0 0.5rem;';
							} else {
								elem.style.cssText = 'padding:  0 0.5rem;';
							}
						});
					}

					const markupLabel = chipsetPaymentContainer?.querySelector('#label-id9');

					const markupLabelHeight = markupLabel?.getBoundingClientRect()?.height;
					const submitButton = chipsetPaymentContainer?.querySelector('#js-submit');
					const computedStyle = window.getComputedStyle(submitButton);
					if (markupLabelHeight > 24) {
						const buttonMarginTop = computedStyle.marginTop;

						if (buttonMarginTop !== '0px') {
							isMarginApplied = true;
							submitButton.style.marginTop = (markupLabelHeight < 60) ? '3.7rem' : '5.1rem';
						}
					} else {
						const buttonMarginTop = computedStyle.marginTop;
						if (isMarginApplied && buttonMarginTop !== '0px') {
							submitButton.style.marginTop = '2.1rem';
						} else if (buttonMarginTop) {
							submitButton.style.marginTop = '0px';
						}
					}
				});
				resizeObserver?.observe(chipsetPaymentContainer);
			}
		}

		// Add caption input values to donation values array
		if (captionInput.length > 0) {
			let i;
			for (i = 0; i < captionInput.length; i++) {
				captionInputValues.push(captionInput[i].value);
			}
		}

		// Add click event to donation amount inputs
		if (donationAmountInputs.length > 0) {
			let i;
			for (i = 0; i < donationAmountInputs.length; i++) {
				if (donationAmountInputs[i].checked === true) {
					const checkedChipText = donationAmountInputs[i].dataset.amountText;
					const checkedChipId = donationAmountInputs[i].id;
					const controlSetsWrapper = donationAmountInputs[i].closest('.productconfig-wrapper');
					const checkedChipContainer = controlSetsWrapper.querySelector("[data-control='" + checkedChipId + "']");
					const chipTextContainer = checkedChipContainer.querySelector('.chip-text');
					chipTextContainer.style.visibility = 'visible';
					// Set chip information text
					if (chipTextContainer != null) {
						chipTextContainer.innerHTML = checkedChipText;
					}
				}
				donationAmountInputs[i].addEventListener('click', (event) => {
					const thisElement = event.target;
					const thisDefaultAmount = thisElement.dataset.defaultAmount;
					const defaultSelectedInput = thisElement.closest('.js-product-config').querySelector('.chip-set-container:not(.js-hidden) input[value="' + thisDefaultAmount + '"]');
					const checkedChipText = thisElement.dataset.amountText;
					const chipTextContainer = thisElement.closest('.productconfig-wrapper').querySelector('.chip-text');
					chipTextContainer.style.visibility = 'visible';
					// Set chip information text
					if (chipTextContainer != null) {
						chipTextContainer.innerHTML = checkedChipText;
					}
					// Check the relevant input
					if (defaultSelectedInput != null) {
						defaultSelectedInput.checked = true;
					}
					selectCaptionChipProductConfig(event);
				});
			}
		}

		// Add click event to product config chips
		if (productConfigCaptionChips.length > 0) {
			let i;
			for (i = 0; i < productConfigCaptionChips.length; i++) {
				productConfigCaptionChips[i].addEventListener('input', (event) => {
					selectCaptionChipProductConfig(event);
				});
			}
		}

		// Get Cookie function
		function getCookie(cname) {
			const name = cname + '=';
			const ca = document.cookie.split(';');
			for (let i = 0; i < ca.length; i++) {
				let c = ca[i];
				while (c.charAt(0) === ' ') {
					c = c.substring(1);
				}
				if (c.indexOf(name) === 0) {
					return c.substring(name.length, c.length);
				}
			}
			return '';
		}

		// Function to Show or hide error message
		const showHideError = (manualDonationValue, manualInputLabel, errorMessage, errorMessageMax, chipTextContainer) => {
			const productconfigcontainer = chipTextContainer.closest('.js-product-config');
			const paymentFreqInput = productconfigcontainer.querySelector('.js-toggle-donation-amounts-control input:checked');
			const errorMessageText = productconfigcontainer.querySelector('#manual-error .input__msg-text');
			const errorMessageMaxText = productconfigcontainer.querySelector('#manual-error-max .input__msg-text');

			// Check min amount
			let paymentFreqInputMinAmount = 0;
			let paymentFreqInputMaxAmount = 0;

			// If no min amount is set by product config, use min amount set on manual input
			if (paymentFreqInput != null) {
				paymentFreqInputMinAmount = parseInt(paymentFreqInput.dataset.minAmount) || 0;
				errorMessageText.innerText = paymentFreqInput.dataset.minError;
				paymentFreqInputMaxAmount = parseInt(paymentFreqInput.dataset.maxAmount) || 0;
				if (errorMessageMaxText) {
					errorMessageMaxText.innerText = paymentFreqInput.dataset.maxError;
				}
			} else {
				paymentFreqInputMinAmount = parseInt(productConfigInput.dataset.minDonation) || 0;
				paymentFreqInputMaxAmount = parseInt(productConfigInput.dataset.maxDonation) || 0;
			}

			if (manualDonationValue < paymentFreqInputMinAmount) {
				manualInputLabel.classList.add('text-field--error');
				errorMessage.classList.remove('js-hidden');
			} else {
				errorMessage.classList.add('js-hidden');
				if (paymentFreqInputMaxAmount > 0 && manualDonationValue > paymentFreqInputMaxAmount) {
					manualInputLabel.classList.add('text-field--error');
					errorMessageMax.classList.remove('js-hidden');
				} else {
					manualInputLabel.classList.remove('text-field--error');
					errorMessageMax.classList.add('js-hidden');
					chipTextContainer.innerHTML = '';
				}
			}
		};

		// Highlight donation chip if manual input is the same as chip value, validate manual input based on minimum amount
		if (productConfigCaptionChips.length > 0) {
			const manualInputLabel = productConfigWidgetFull[k].querySelector('.text-field--currency');
			const errorMessage = productConfigWidgetFull[k].querySelector('#manual-error');
			const errorMessageMax = productConfigWidgetFull[k].querySelector('#manual-error-max');
			const chipTextContainer = productConfigWidgetFull[k].querySelector('.chip-set-container:not(.js-hidden) .chip-text');
			const currentSelectedValue = productConfigWidgetFull[k].querySelector('.js-toggle-donation-amounts-set input:checked');

			// On page load validate cookie value if it exists
			let cookieDonationAmount = getCookie('wv_donationAmount');
			if (cookieDonationAmount || productConfigInput.classList.contains('input-validation-error')) {
				cookieDonationAmount = cookieDonationAmount === '' ? 0 : (parseInt(cookieDonationAmount) || 0);
				showHideError(cookieDonationAmount, manualInputLabel, errorMessage, errorMessageMax, chipTextContainer);
			}

			// Validate any manual input value
			productConfigInput.addEventListener('keyup', function(e) {
				const manualDonationValue = this.value === '' ? 0 : parseInt(this.value);

				// Hide any associated chip text if the value of the input is 0
				if (currentSelectedValue != null) {
					this.closest('.transaction-form').querySelector('.chip-set-container:not(.js-hidden) .chip-text').innerHTML = '';
				}

				// See if typed value exists in donations array
				if (captionInputValues.indexOf(this.value) > -1) {
					const typedInput = this.closest('.transaction-form').querySelector('.chip-set-container:not(.js-hidden) input[value="' + manualDonationValue + '"]');

					// Uncheck all other inputs
					if (captionInput.length > 0) {
						let i;
						for (i = 0; i < captionInput.length; i++) {
							captionInput[i].checked = false;
						}
					}
					showHideError(manualDonationValue, manualInputLabel, errorMessage, errorMessageMax, chipTextContainer);
					// Check the relevant input
					typedInput.checked = true;
					selectCaptionChipProductConfig();
				} else {
					showHideError(manualDonationValue, manualInputLabel, errorMessage, errorMessageMax, chipTextContainer);

					// Uncheck all other inputs
					if (captionInput.length > 0) {
						let i;
						for (i = 0; i < captionInput.length; i++) {
							captionInput[i].checked = false;
						}
					}

					if (currentSelectedValue != null) {
						// Remove selected class
						currentSelectedValue.checked = false;
						// Hide donation text
						chipTextContainer.innerHTML = '';
					}
				}
			});
		}

		if (captionInput.length > 0) {
			if (parseInt(productConfigInput.value) >= parseInt(productConfigInput.dataset.minDonation)) {
				const manualInputLabel = productConfigWidgetFull[k].querySelector('.text-field--currency');
				const errorMessages = productConfigWidgetFull[k].querySelectorAll('.input__msg--error');
				// Remove any error state
				manualInputLabel.classList.remove('text-field--error');
				productConfigInput.classList.remove('input-validation-error');
				let j;
				for (j = 0; j < errorMessages.length; j++) {
					errorMessages[j].classList.add('js-hidden');
				}
			}
			let i;
			for (i = 0; i < captionInput.length; i++) {
				if (captionInput[i].checked === true && captionInput[i].value === productConfigInput.value) {
					const checkedChipText = captionInput[i].dataset.amountText;
					const chipTextContainer = captionInput[i].closest('.chip-set-container').querySelector('.chip-text');
					chipTextContainer.style.display = 'block';

					// Set chip information text
					if (chipTextContainer != null) {
						chipTextContainer.innerHTML = checkedChipText;
					}
				} else {
					captionInput[i].checked = false;
				}
			}
		}

		// Select caption function
		function selectCaptionChipProductConfig(event) {
			const newproductConfigWidgetFull = event.target.closest('.js-product-config');
			captionInput = newproductConfigWidgetFull.querySelectorAll('.chip-set-container:not(.js-hidden) .chip-input');
			captionInputValues = [];
			// Add caption input values to donation values array
			if (captionInput.length > 0) {
				let i;
				for (i = 0; i < captionInput.length; i++) {
					captionInputValues.push(captionInput[i].value);
				}
			}

			const manualInputLabel = newproductConfigWidgetFull.querySelector('.text-field--currency');
			const errorMessages = newproductConfigWidgetFull.querySelectorAll('.input__msg--error');

			if (captionInput.length > 0) {
				let i;
				for (i = 0; i < captionInput.length; i++) {
					if (captionInput[i].checked === true) {
						const checkedChipText = captionInput[i].dataset.amountText;
						const checkedChipValue = captionInput[i].value;
						const chipTextContainer = captionInput[i].closest('.chip-set-container').querySelector('.chip-text');

						// Remove any error state
						manualInputLabel.classList.remove('text-field--error');
						productConfigInput.classList.remove('input-validation-error');
						let j;
						for (j = 0; j < errorMessages.length; j++) {
							errorMessages[j].classList.add('js-hidden');
						}
						productConfigInput.value = checkedChipValue;
						chipTextContainer.style.display = 'block';

						// Set chip information text
						if (chipTextContainer != null) {
							chipTextContainer.innerHTML = checkedChipText;
						}
					} else {
						captionInput[i].checked = false;
					}
				}
			}
		}

		// Function to check child ID against regex
		const checkChildID = function(childID, input, event) {
			const childIDRegex = /^([A-Z]{3}[-][0-9]{6}[-][0-9]{4})$/;
			const parentDiv = input.closest('.form__input-container');
			const inputLabel = input.closest('.text-field');
			const inputError = parentDiv.querySelector('.input__msg');
			if (childIDRegex.test(childID) !== false) {
				// If child ID input value does not match regex
				inputError.classList.remove('js-hidden');
				inputError.classList.remove('field-validation-valid');
				inputError.classList.add('field-validation-invalid');
				inputLabel.classList.add('text-field--error');
				// event.preventDefault();
			} else { // If child ID input value does match regex
				inputError.classList.remove('field-validation-invalid');
				inputError.classList.add('field-validation-valid');
				inputLabel.classList.remove('text-field--error');
			}
		};

		// Custom validation for when form is submitted
		if (productConfigForm != null) {
			productConfigForm.addEventListener('submit', function(event) {
				// Validation on Child ID input field - 'XXX-123456-1234' (hyphens required)
				if (childIDInput != null) {
					checkChildID(childIDInput.value, childIDInput, event);
				}
			});
		}
	}
}
