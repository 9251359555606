// Validate email on frontend
const validateEmailInput = document.querySelectorAll('.js-validate-email');

// Validate email function with regex
function validateEmail(email) {
	/*eslint-disable */
	const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
	/* eslint-enable */
	return re.test(email);
}

if (validateEmailInput.length > 0) {
	let i;
	for (i = 0; i < validateEmailInput.length; i++) {
		validateEmailInput[i].addEventListener('blur', function(e) {
			const emailInputValue = this.value;
			// Show/hide error/success messages depending on whether or not email is valid
			if (validateEmail(emailInputValue) === true) {
				this.parentNode.classList.remove('text-field--error');
				this.parentNode.classList.add('text-field--success');
			} else {
				this.parentNode.classList.add('text-field--error');
				this.parentNode.classList.remove('text-field--success');
			}
		});
	}
}
