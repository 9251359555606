// Select video widgets if they exist on page
const videoWidget = document.querySelectorAll('.video-ds2');

if (videoWidget.length > 0) {
	let i;
	for (i = 0; i < videoWidget.length; i++) {
		const videoOverlayLink = videoWidget[i].querySelector('.video-ds2__overlay');
		const videoWidgetSrc = videoWidget[i].querySelector('.video__src');

		// If video widget has html5 embedded video, pause it on page load
		if (videoOverlayLink != null && videoWidgetSrc != null) {
			videoWidgetSrc.pause();

			const videoOverlay = videoWidget[i].querySelector('.video-ds2__overlay');
			const videoOverlayLink = videoOverlay.querySelector('.video-ds2__overlay-link');

			videoOverlayLink.addEventListener('click', function(e) {
				e.preventDefault();
				videoOverlay.remove();
				videoWidgetSrc.play();
			});
		}
	}
}
