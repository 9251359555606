// JS for Product Configuration Banner
const productConfigBanner = document.querySelectorAll('.product-config-validate');

if (productConfigBanner != null) {
	let i;
	for (i = 0; i < productConfigBanner.length; i++) {
		const productConfigManualInput = productConfigBanner[i].querySelector('input[type="number"]');

		productConfigManualInput.addEventListener('keypress', function(e) {
			// Validation
			if (e.which < 48 || e.which > 57) {
				e.preventDefault();
			}
		});
		productConfigManualInput.addEventListener('keyup', function(e) {
			// Restrict this from being greater than the maxlength
			if (this.value.length > this.maxLength) {
				this.value = this.value.slice(0, this.maxLength);
			}
		});
	}
}
