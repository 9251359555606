/* eslint-disable */
var x, i, j, l, ll, selElmnt, a, b, c;
/* Look for any elements with the class "dropdown": */
x = document.getElementsByClassName('dropdown');
l = x.length;
for (i = 0; i < l; i++) {
	selElmnt = x[i].getElementsByTagName('select')[0];
	optGroupElmnt = x[i].getElementsByTagName('optgroup');
	ll = selElmnt.length;
	ogl = optGroupElmnt.length;
	/* For each element, create a new DIV that will act as the selected item: */
	a = document.createElement('DIV');
	a.setAttribute('class', 'select-selected');
	a.setAttribute('data-tab', selElmnt.selectedIndex);
	a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
	x[i].appendChild(a);
	/* For each element, create a new DIV that will contain the option list: */
	b = document.createElement('DIV');
	b.setAttribute('class', 'select-items select-hide');
	// Find hidden select value field
	let hiddenSelectedValueField = x[i].parentNode.querySelector('.js-select__value');
	let hiddenSelectedValue = hiddenSelectedValueField.value;

	// Retain dropdown value if it has already been selected
	if (hiddenSelectedValue.length > 0) {
		let selected = x[i].querySelector('.select-selected');
		let selectedOption = x[i].querySelector('select option[data-value="' + hiddenSelectedValue  + '"]');
		let selectedOptionVal = selectedOption.value;
		let dropdownSelect = x[i].querySelector('select');

		if (dropdownSelect.classList.contains('select-local-country')) {
			selected.innerHTML = selectedOptionVal;
			document.querySelector('.js-country__value').value = selectedOptionVal;
		} else {
			selected.innerHTML = selectedOption.innerHTML;
		}
		selectedOption.dataset.selected = true;
		selectedOption.selected = true;
	}
	// If any options are grouped, render them within the group
	if (ogl > 0) {
		for (j = 0; j < ogl; j++) {
			c = document.createElement('DIV');
			c.setAttribute('class', 'group-element');
			d = document.createElement('SPAN');
			d.setAttribute('class', 'group-element__label');
			d.innerHTML = optGroupElmnt[j].getAttribute('label');
			c.appendChild(d);

			let groupChildOption;

			const thisChildOption = optGroupElmnt[j].childNodes;
			const thisChildOptionLength = optGroupElmnt[j].childNodes.length;
			var index = 1;
			if (selElmnt.id == 'language') {
				index = 0;
			}
			for (k = index; k < thisChildOptionLength; k++) {
				if (thisChildOption[k].tagName === 'OPTION') {
					groupChildOption = document.createElement('DIV');
					groupChildOption.setAttribute('class', 'select-items__option');
					groupChildOption.innerHTML = thisChildOption[k].innerHTML;

					c.appendChild(groupChildOption);

					groupChildOption.addEventListener('click', function(e) {
						/* When an item is clicked, update the original select box,
						and the selected item: */
						let y;
						let i;
						let k;
						let s;
						let h;
						let sl;
						let yl;
						s = this.parentNode.parentNode.parentNode.getElementsByTagName('select')[0];
						sl = s.length;
						h = this.parentNode.parentNode.previousSibling;
						for (i = 0; i < sl; i++) {
							if (s.options[i].innerHTML === this.innerHTML) {
								s.selectedIndex = i;
								h.innerHTML = this.innerHTML;
								y = this.parentNode.parentNode.getElementsByClassName('same-as-selected');
								yl = y.length;
								for (k = 0; k < yl; k++) {
									y[k].removeAttribute('class');
								}
								this.setAttribute('class', 'same-as-selected');
								break;
							}
						}
						h.click();
					});
				}
			}
			b.appendChild(c);
		}
	} else { // Render regular select options
		var index = 1;
		if (selElmnt.id == 'language') {
			index = 0;
        }
		for (j = index; j < ll; j++) {
			/* For each option in the original select element,
			create a new DIV that will act as an option item: */
			c = document.createElement('DIV');
			c.setAttribute('class', 'select-items__option');
			c.setAttribute('data-value', selElmnt.options[j].dataset.value);
			c.setAttribute('data-index', j);
			c.innerHTML = selElmnt.options[j].innerHTML;
			c.addEventListener('click', function(e) {
				/* When an item is clicked, update the original select box,
				and the selected item: */
				let y;
				let i;
				let k;
				let s;
				let h;
				let sl;
				let yl;
				s = this.parentNode.parentNode.getElementsByTagName('select')[0];
				sl = s.length;
				h = this.parentNode.previousSibling;
				for (i = 0; i < sl; i++) {
					var checkoutpersonaldetailsSubmit = document.getElementById('checkoutpersonaldetails-submit');
					if (s.options[i].innerHTML === this.innerHTML) {
						s.selectedIndex = i;
						h.innerHTML = this.innerHTML;
						if (s.id == "titledropdown" && checkoutpersonaldetailsSubmit != null) {
							checkoutpersonaldetailsSubmit.disabled = false;
						}
						if (s.id == "mpaybankdropdown") {
							document.getElementById('spinny-wheel').style.visibility = "hidden";
							document.getElementById('internet-banking-submit').disabled = false;
							document.getElementById('internet-banking-back').classList.remove("disabled");
						}
						if (s.classList.contains('select-local-country')) {
							document.querySelector('.js-country__value').value = this.innerHTML;
						}
						h.dataset.tab = this.dataset.index;
						y = this.parentNode.getElementsByClassName('same-as-selected');
						yl = y.length;
						for (k = 0; k < yl; k++) {
							y[k].classList.add('select-items__option');
							y[k].classList.remove('same-as-selected');
						}
						this.classList.add('same-as-selected');
						if (hiddenSelectedValueField) {
							hiddenSelectedValueField.value = this.dataset.value;
						}
						break;
					}
				}
				h.click();
			});
			b.appendChild(c);
		}
	}
	x[i].appendChild(b);
	a.addEventListener('click', function(e) {
		/* When the select box is clicked, close any other select boxes,
		and open/close the current select box: */
		e.stopPropagation();
		closeAllSelect(this);
		this.nextSibling.classList.toggle('select-hide');
		this.classList.toggle('select-arrow-active');
	});
}

function closeAllSelect(elmnt) {
	/* A function that will close all select boxes in the document,
	except the current select box: */
	let x;
	let y;
	let i;
	let xl;
	let yl;
	let arrNo = [];
	x = document.getElementsByClassName('select-items');
	y = document.getElementsByClassName('select-selected');
	xl = x.length;
	yl = y.length;
	for (i = 0; i < yl; i++) {
		if (elmnt === y[i]) {
			arrNo.push(i);
		} else {
			y[i].classList.remove('select-arrow-active');
		}
	}
	for (i = 0; i < xl; i++) {
		if (arrNo.indexOf(i)) {
			x[i].classList.add('select-hide');
		}
	}
}

/* If the user clicks anywhere outside the select box,
then close all select boxes: */
document.addEventListener('click', closeAllSelect);
// Auto-select local country on country select dropdown
const localCountrySelect = document.querySelector('.select-local-country');

if (localCountrySelect != null) {
	const hiddenInput = localCountrySelect.closest('.form__input-container').querySelector('.js-select__value');

	// If this is the first time the user has landed on the form
	if (hiddenInput.value === '') {

		// Get locale from meta tag
		const metaLocale = document.head.querySelector('[http-equiv~=content-language][content]').content;
		// Trim meta tag so it matches value in select list
		const metaLocaleTrim = metaLocale.split('-').pop();
		// Set value of hidden input field
		hiddenInput.value = metaLocaleTrim;

		// set variable for local select option
		const localCountryOption = localCountrySelect.querySelector('option[data-value="' + metaLocaleTrim + '"]');

		// If local country exists in dropdown
		if (localCountryOption != null) {
			// get local select option text
			// Auto-select local country on country select dropdown for desktop
			let localCountryTitle = localCountryOption.innerText;
			localCountryTitle = localCountryTitle.replace(/(\r\n|\n|\r)/gm, '');
			// get selected text element
			const selectedText = localCountrySelect.parentNode.querySelector('.select-selected');
			// Populate selected text with local country title
			selectedText.innerText = localCountryTitle;
			document.querySelector('.js-country__value').value = localCountryTitle;

			// Auto-select local country on country select dropdown for mobile
			let localCountryTitleMobile = localCountryOption.value;
			localCountrySelect.value = localCountryTitleMobile;
		}
	}
}
// If mobile select dropdown is changed reflect this in desktop select dropdown
const dropdownSelect = document.querySelectorAll('.dropdown select');

if (dropdownSelect.length > 0) {
	let i;
	for (i = 0; i < dropdownSelect.length; i++) {
		dropdownSelect[i].addEventListener('change', function (e) {
			let option = this.value;
			const selectedOption = this.parentNode.querySelector('.select-selected');
			const selectedJsValue = this.parentNode.parentNode.querySelector('.js-select__value');
			selectedOption.dataset.tab = this.selectedIndex;
			selectedOption.innerText = this.selectedOptions[0].innerText;

			if (this.id == "titledropdown") {
				document.getElementById('checkoutpersonaldetails-submit').disabled = false;
			}
			if (this.classList.contains('select-local-country')) {
				selectedJsValue.value = this.selectedOptions[0].dataset.value;
				document.querySelector('.js-country__value').value = option;
			} else {
				selectedJsValue.value = option;
			}
		});
	}
}
