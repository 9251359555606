/* eslint-disable */
const videoContainers = document.querySelectorAll('.video__container');

if (videoContainers.length > 0) {

	function debounce(fn, ms) {
		var time = null;
		return function() {
			var a = arguments, t = this;
			clearTimeout(time);
			time = setTimeout(function() { fn.apply(t, a); }, ms);
		}
	}

	function throttle(fn, ms) {
		var time, last = 0;
		return function() {
			var a = arguments, t = this, now = + (new Date), exe = function() { last = now; fn.apply(t, a); };
			clearTimeout(time);
			(now >= last + ms) ? exe() : time = setTimeout(exe, ms);
		}
	}

	function hasClass(el, cls) {
		if (el.className.match('(?:^|\\s)' + cls + '(?!\\S)')) { return true; }
	}

	function addClass(el, cls) {
		if (!el.className.match('(?:^|\\s)' + cls + '(?!\\S)')) { el.className += ' '+cls; }
	}

	function delClass(el, cls) {
		el.className = el.className.replace(new RegExp('(?:^|\\s)' + cls + '(?!\\S)'),'');
	}

	function stripYouTubeEmbed() {
		var embed = document.querySelectorAll('iframe[src^="https://www.youtube.com/embed/"]'), length = embed.length, i, url, id;
		for (i = 0; i < length; ++i) {
			url = embed[i].getAttribute('src');
			id = url.substr(url.lastIndexOf('/') + 1); // get video id from youtube embed url
			embed[i].outerHTML = '<div class="video__container"><div class="youtube" data-embed="' + id + '"><div class="youtube-play"><\/div><\/div><\/div>';
		}
	}

	stripYouTubeEmbed();

	function LazyLoadYouTubeEmbed() {
		var yt = document.querySelectorAll('.yt-load:not(.yt-loaded)'), // '.yt-load' class added via js elementFromTop() function
		length = yt.length, i;

		for (i = 0; i < length; ++i) {
			var iframe = document.createElement('iframe');
			iframe.setAttribute('frameborder', '0');
			iframe.setAttribute('allowfullscreen', '');
			iframe.setAttribute('src', 'https://www.youtube.com/embed/' + yt[i].dataset.embed);
			yt[i].innerHTML = '';
			yt[i].appendChild(iframe);

			addClass(yt[i], 'yt-loaded'); // remove elem from next querySelectAll() array when function fires again
		}
	}

	function elementFromTop(elemTrigger, elemTarget, classToAdd, distanceFromTop, unit) {
		var winY = window.innerHeight || document.documentElement.clientHeight,
			elTriggerLength = elemTrigger.length,
			elTargetLength, distTop, distPercent, distPixels, distUnit, elTarget, i, j;

		for (i = 0; i < elTriggerLength; ++i) {
			elTarget = document.querySelectorAll('.' + elemTarget);
			elTargetLength = elTarget.length;
			distTop = elemTrigger[i].getBoundingClientRect().top;
			distPercent = Math.round((distTop / winY) * 100);
			distPixels = Math.round(distTop);
			distUnit = unit == 'percent' ? distPercent : distPixels;
			if (distUnit <= distanceFromTop) {
				if (!hasClass(elemTrigger[i], elemTarget)) {
					for (j = 0; j < elTargetLength; ++j) {
						if (!hasClass(elTarget[j], classToAdd)) { addClass(elTarget[j], classToAdd); }
					}
				} else {
					if (!hasClass(elemTrigger[i], classToAdd)) { addClass(elemTrigger[i], classToAdd); }
				}
			} else {
				delClass(elemTrigger[i], classToAdd);
				if (!hasClass(elemTrigger[i], elemTarget)) {
					for (j = 0; j < elTargetLength; ++j) { delClass(elTarget[j], classToAdd); }
				}
			}
		}
	}
	// params:  trigger element, target element class, classes to add to target element, trigger element distance from top, unit ('percent' or 'pixels')
	// usage:   elementFromTop(elemTrigger, elemTarget, classToAdd, distanceFromTop, unit);

	window.addEventListener('scroll', throttle(function() {
		elementFromTop(document.querySelectorAll('.youtube'), 'youtube', 'yt-load', 150, 'percent'); // half a screen below the fold
		LazyLoadYouTubeEmbed();
	}, 100), false);

	window.addEventListener('resize', debounce(function() {
		elementFromTop(document.querySelectorAll('.youtube'), 'youtube', 'yt-load', 150, 'percent'); // half a screen below the fold
		LazyLoadYouTubeEmbed();
	}, 100), false);

	window.addEventListener('load', function() {
		elementFromTop(document.querySelectorAll('.youtube'), 'youtube', 'yt-load', 150, 'percent'); // half a screen below the fold
		LazyLoadYouTubeEmbed();
	}, false);
};
