// JS for Notification Banner
const notificationBanner = document.querySelectorAll('.notification-banner');

if (notificationBanner != null) {
	let i;
	for (i = 0; i < notificationBanner.length; i++) {
		const notficiationBannerCloseDesktop = notificationBanner[i].querySelector('.notification-banner__close-desktop');
		const notficiationBannerCloseMobile = notificationBanner[i].querySelector('.notification-banner__close-mobile');

		// Close associated notification banner
		notficiationBannerCloseDesktop.addEventListener('click', function(e) {
			e.preventDefault();
			const openBanners = document.querySelectorAll('.payment-notification.notification-banner');
			// Hide all payment dialogs
			let i;
			for (i = 0; i < openBanners.length; i++) {
				openBanners[i].parentNode.remove();
			}
			if (this.closest('.payment-error-overlay') != null) {
				this.parentNode.parentNode.remove();
			} else {
				this.parentNode.remove();
			}
		});

		// Close associated notification banner
		notficiationBannerCloseMobile.addEventListener('click', function(e) {
			e.preventDefault();
			const openBannersMobile = document.querySelectorAll('.payment-notification.notification-banner');
			// Hide all payment dialogs
			let i;
			for (i = 0; i < openBannersMobile.length; i++) {
				openBannersMobile[i].parentNode.remove();
			}
			if (this.closest('.payment-error-overlay') != null) {
				this.parentNode.parentNode.remove();
			} else {
				this.parentNode.remove();
			}
		});
	}
}
