const validatePhoneInput = document.getElementsByClassName('js-validate-phone');
const phoneErrormessage = document.querySelector('#phone-error');
if (validatePhoneInput.length > 0) {
	const minimumlength = validatePhoneInput[0].dataset.minlength;
	let i;
	for (i = 0; i < validatePhoneInput.length; i++) {
		validatePhoneInput[i].addEventListener('blur', showErrormsg, false);
	}
	function showErrormsg() {
		if (this.value.length >= minimumlength || this.value.length === 0) {
			phoneErrormessage.classList.add('js-hidden');
			this.classList.remove('js-minimum-valid');
		} else {
			phoneErrormessage.classList.remove('js-hidden');
			this.classList.add('js-minimum-valid');
		}
	}
}
