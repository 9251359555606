// scrollBy functionality on Hero elements
document.addEventListener('click', function(e) {
	if (e.target.tagName !== 'DIV') return;
	if (e.target.classList.contains('hero__scroll-link')) {
		scrollAnchors(e, e.target);
	}
});

function scrollAnchors(e, respond = null) {
	function distanceToTop(el) {
		return Math.floor(el.getBoundingClientRect().top);
	}
	e.preventDefault();

	const targetAnchor = e.target.parentNode.parentNode.nextElementSibling;
	if (!targetAnchor) return;
	const originalTop = distanceToTop(targetAnchor);
	window.scrollBy({ top: originalTop, left: 0, behavior: 'smooth' });
}
