const searchButton = document.getElementById('addgift-btn-submit');
const searchnotification = document.getElementById('multiplegift-notification-banner');

if (searchButton != null) {
	searchButton.onclick = function(e) {
		searchnotification.parentNode.classList.add('show');
		e.preventDefault();
		// DataLayer push to track behaviour of multiple gift button
		/* eslint-disable */
		if (typeof dataLayer !== 'undefined' && typeof doesAnalyticalCookieExist !== 'undefined') {
			dataLayer.push({
				'event': 'gen_ev_navigation',
				'event_category': 'navigation',
				'event_action': 'add to cart stub test',
				'event_label': 'multiple gift stub test'
			});
		}
		/* eslint-enable */
	};
}

const Sendphysicalcard = document.getElementById('sendphysical-card');
const Giftcardbanner = document.getElementById('giftcard-banner');

if (Sendphysicalcard != null && Giftcardbanner != null) {
	Sendphysicalcard.onclick = function(e) {
		Giftcardbanner.parentNode.classList.add('show');
		e.preventDefault();
		// DataLayer push to track behaviour of physical gift card button
		/* eslint-disable */
		if (typeof dataLayer !== 'undefined' && typeof doesAnalyticalCookieExist !== 'undefined') {
			dataLayer.push({
				'event': 'physical_gift_card',
				'event_category': 'card',
				'event_action': 'physical gift card stub test',
				'event_label': 'physical gift card stub test'
			});
		}
		/* eslint-disable */
	};
}

if (document.getElementById('close-banner') != null) {
	document.getElementById('close-banner').addEventListener('click', function() {
		const tabs = document.querySelectorAll('#tabs li');
		tabs?.[0]?.classList?.add('active');
		tabs?.[1]?.classList?.remove('active');
		document.querySelectorAll('#tab div.tab-content-item')?.[0].classList.add('active');
		document.querySelectorAll('#tab div.tab-content-item')?.[1].classList.remove('active');
	});
}

document.addEventListener('DOMContentLoaded', function() {
	const tabs = document.querySelectorAll('#tabs li');
	if (tabs.length > 0) {
		tabs.forEach(function(tab) {
			tab.addEventListener('click', function(e) {
				if (!this.classList.contains('active')) {
					const tabNum = Array.from(tabs).indexOf(this);
					const nthChild = tabNum + 1;
					document.querySelector('#tabs li.active').classList.remove('active');
					this.classList.add('active');
					document.querySelector('#tab div.tab-content-item.active').classList.remove('active');
					document.querySelector('#tab div.tab-content-item:nth-child(' + nthChild + ')').classList.add('active');
				}
			});
		});
	}
});
