// Clear all filters within the assigned link's parent container
const clearLinks = document.querySelectorAll('.js-clear-filters-link');

if (clearLinks.length > 0) {
	let i;
	for (i = 0; i < clearLinks.length; i++) {
		// For each cleared link get the parent container
		const filtersContainer = clearLinks[i].closest('.filter-container');

		clearLinks[i].addEventListener('click', function(e) {
			e.preventDefault();

			// Clear all checkboxes
			const checkboxes = filtersContainer.querySelectorAll('.checkbox__input');
			if (checkboxes.length > 0) {
				let i;
				for (i = 0; i < checkboxes.length; i++) {
					checkboxes[i].checked = false;
				}
			}

			// Clear all select dropdowns
			const dropdowns = filtersContainer.querySelectorAll('.dropdown select');
			if (dropdowns.length > 0) {
				let i;
				for (i = 0; i < dropdowns.length; i++) {
					const dropdownParent = dropdowns[i].closest('.form__input-container');
					const dropdownHiddenValue = dropdownParent.querySelector('.js-select__value');
					const dropdownFirstOptionText = dropdowns[i].options[0].text;
					const dropdownVisibleText = dropdownParent.querySelector('.select-selected');
					const sameAsSelected = dropdownParent.querySelector('.same-as-selected');

					// Restore first option to selected option:
					// Set visible text to first option
					dropdownVisibleText.innerText = dropdownFirstOptionText;
					// Remove selected styling from selected option if there is one
					if (sameAsSelected != null) {
						sameAsSelected.classList.remove('same-as-selected');
					}
					// Clear hidden selected value input
					dropdownHiddenValue.value = '';
				}
			}
		});
	}
}
