const activeStory = document.querySelectorAll('.story-v2-active');
const storySlide = document.querySelectorAll('.story-v2-slide');
const storySlideActive = document.querySelectorAll('.story-v2-slide.tns-slide-active');
const prevStory = document.querySelectorAll('.carousel__container--story-v2 .carousel__prev');
const nextStory = document.querySelectorAll('.carousel__container--story-v2 .carousel__next');
const storyCarousel = document.getElementById('storyCarousel-UNIQUE-items');
let slideCount = 0;
const storyWidth = 90 / storySlide.length;
const activeStoryWidth = 120 / storySlide.length;
// media query event handler
const mq = window.matchMedia('(min-width: 1024px)');
mq.addListener(WidthChange);
WidthChange(mq);

function WidthChange(mq) {
	// desktop devices
	if (mq.matches) {
		if (storySlideActive.length > 0) {
			let i;
			for (i = 0; i < storySlideActive.length; i++) {
				// add active class to first story on desktop
				storySlideActive[0].classList.add('story-v2-active');
			}
		}

		if (nextStory.length > 0) {
			let i;
			for (i = 0; i < nextStory.length; i++) {
				// click on next button
				nextStory[i].addEventListener('click', function(e) {
					// check for active class
					const currentActiveStory = document.querySelectorAll('.story-v2-active');
					if (currentActiveStory.length > 0) {
						let i;
						for (i = 0; i < currentActiveStory.length; i++) {
							// add active class to next element
							currentActiveStory[i].nextElementSibling.classList.add('story-v2-active');
							// remove active class for current element
							currentActiveStory[i].classList.remove('story-v2-active');
							currentActiveStory[i].nextElementSibling.style.cssText = 'width:' + activeStoryWidth + '%';
							currentActiveStory[i].style.cssText = 'width:' + storyWidth + '%';
						}
					}
					// custom slider
					if (storyCarousel != null) {
						console.log('ok');
						const increment = slideCount - 30;
						storyCarousel.style.cssText = 'left: ' + increment + '%; position: relative;';
						slideCount -= 30;
						return slideCount;
					}
				});
			}
		}

		if (prevStory.length > 0) {
			let i;
			for (i = 0; i < prevStory.length; i++) {
				// click on prev button
				prevStory[i].addEventListener('click', function(e) {
					const currentActiveStory = document.querySelectorAll('.story-v2-active');
					if (currentActiveStory.length > 0) {
						let i;
						for (i = 0; i < currentActiveStory.length; i++) {
							// add active class for previous story
							currentActiveStory[i].previousElementSibling.classList.add('story-v2-active');
							// remove active class for current element
							currentActiveStory[i].classList.remove('story-v2-active');
							currentActiveStory[i].previousElementSibling.style.cssText = 'width:' + activeStoryWidth + '%';
							currentActiveStory[i].style.cssText = 'width:' + storyWidth + '%';
						}
					}
					if (storyCarousel != null) {
						const decrement = slideCount + 30;
						storyCarousel.style.cssText = 'left: ' + decrement + '%; position: relative;';
						slideCount += 30;
						return slideCount;
					}
				});
			}
		}

		if (activeStory.length > 0) {
			if (storySlide.length > 0) {
				let i;
				for (i = 0; i < storySlide.length; i++) {
					// set width of active story
					if (storySlide[i].classList.contains('story-v2-active')) {
						storySlide[i].style.cssText = 'width:' + activeStoryWidth + '%';
					} else {
						storySlide[i].style.cssText = 'width:' + storyWidth + '%';
					}
					// click on story makes active
					storySlide[i].onclick = function(e) {
						e.preventDefault();
						this.parentElement.querySelectorAll('.story-v2-active').forEach(e => {
							e.classList.remove('story-v2-active');
							e.style.cssText = 'width:' + storyWidth + '%';
						});
						this.classList.add('story-v2-active');
						this.style.cssText = 'width:' + activeStoryWidth + '%';
					};
				}
			}
		}
	} else {
		// mobile devices
		if (storySlide.length > 0) {
			let i;
			for (i = 0; i < storySlide.length; i++) {
				if (storySlide[i].classList.contains('story-v2-active')) {
					// remove active class used for desktop
					storySlide[i].classList.remove('story-v2-active');
				}
			}
		}
	}
}
