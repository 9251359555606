const handleClickOutsideSearchbtn = (e) => {
	const searchIcon = document.getElementById('sitesearch-iconwrapper');
	const searchPanel = document.querySelector('.search-panel');
	if (searchIcon !== undefined && searchIcon !== null) {
		searchIcon.addEventListener('click', function() {
			this.classList.toggle('borderactive');
			searchPanel.classList.toggle('show');
			setTimeout(function() {
				document.getElementById('sitesearchinput').focus();
			}, 100);
		});

		const siteSearchinputbox = document.querySelector('.sitesearch-input');

		if (e.target !== searchIcon && e.target !== siteSearchinputbox) {
			searchPanel.classList.remove('show');
			searchIcon.classList.remove('borderactive');
		}
	}
};

document.addEventListener('click', handleClickOutsideSearchbtn, true);
