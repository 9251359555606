// CTA tracking on button click
const trackedButtons = document.querySelectorAll('a.button');

if (trackedButtons.length > 0) {
	let i;
	for (i = 0; i < trackedButtons.length; i++) {
		trackedButtons[i].addEventListener('click', function(e) {
			// Get button hierarchy class
			const hierarchyClasslist = this.classList;
			let hierarchyClass;

			if (hierarchyClasslist.contains('button--secondary')) {
				hierarchyClass = 'secondary';
			} else {
				hierarchyClass = 'primary';
			}

			// Get button label text
			let innerTxt = this.textContent || this.innerText;
			innerTxt = innerTxt.trim();

			// Get page path
			const pagePath = window.location.href;

			/* eslint-disable */
			if (typeof dataLayer !== 'undefined' && typeof doesAnalyticalCookieExist !== 'undefined') {
				// Push link title to datalayer
				dataLayer.push({
					'event': 'gen_ev_navigation',
					'event_category': 'navigation',
					'event_action': 'call to action',
					'event_label': hierarchyClass + ' - ' + innerTxt + ' - ' + pagePath,
					'non_interaction': false
				});
			}
			/* eslint-enable */
		});
	}
}
