// Select options hide and show corresponding tabs
const selectOptionTabsContainer = document.querySelector('.select-option__tabs');
const openDialog = document.querySelector('.payment-notification.notification-banner');

if (selectOptionTabsContainer != null) {
	// Get select options within this container
	const selectOptions = selectOptionTabsContainer.querySelectorAll('.select-option');
	const paymentOptionForms = selectOptionTabsContainer.querySelectorAll('.payment-option__content');
	const selectOptionContainer = selectOptionTabsContainer.querySelector('.select-option__container');

	let pairedTab;
	// Click a select option to show the corresponding form
	let j;
	for (j = 0; j < selectOptions.length; j++) {
		selectOptions[j].addEventListener('click', function(e) {
			e.preventDefault();
			// Get corresponding tab
			pairedTab = this.dataset.po;
			// Get this form tab
			const thisFormTab = document.getElementById(pairedTab);
			// Hide select options
			selectOptionContainer.style.display = 'none';
			// Show corresponding form tab
			thisFormTab.style.display = 'block';
		});
	}
	// Click back button to return to select options page
	const selectOptionsBackButtons = document.querySelectorAll('.payment-option__content .js-button-back');

	let k;
	for (k = 0; k < selectOptionsBackButtons.length; k++) {
		selectOptionsBackButtons[k].addEventListener('click', function(e) {
			e.preventDefault();
			// Show select options
			selectOptionContainer.style.display = 'block';
			// Hide all payment forms
			let i;
			for (i = 0; i < paymentOptionForms.length; i++) {
				paymentOptionForms[i].style.display = 'none';
			}
		});
	}

	// If error dialog is showing on payment details form, show the tab it's on by default
	if (openDialog != null) {
		const selectOptionContainer = document.querySelector('.select-option__container');
		let errorID = openDialog.id;
		errorID = errorID.replace('-Dialog', '');
		const errorTabContent = document.getElementById(errorID);

		// Hide select options
		selectOptionContainer.style.display = 'none';
		// Show corresponding form tab
		errorTabContent.style.display = 'block';
	}
}
