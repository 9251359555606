//  To make the dropdown feature active
const handleClickOutside = (e) => {
	if (!(e.target.parentNode.classList.contains('rt-Country') || e.target.parentNode.parentNode.classList.contains('rt-Country'))) {
		if (e.target.classList.contains('multiselect__dropdown__selected') && e.target.parentNode.classList.contains('multiselect__dropdown')) {
			const multiselectList = document.querySelectorAll('.multiselect__dropdown__selected');
			multiselectList.forEach((item, index, arr) => {
				if (e.target.parentNode !== multiselectList[index].parentNode) {
					multiselectList[index].parentNode.classList.remove('active');
				}
			});
			e.target.parentNode.classList.toggle('active');
		} else {
			if (!(e.target.classList.contains('multiselect__dropdown--option') ||
				e.target.classList.contains('multiselect__dropdown--option-checkbox') ||
				e.target.classList.contains('select-option__icon') ||
				e.target.classList.contains('dropdown__list-item'))) {
				const multiselectList = document.querySelectorAll('.multiselect__dropdown__selected');
				multiselectList.forEach((item, index, arr) => {
					multiselectList[index].parentNode.classList.remove('active');
				});
			}
		}
	}
};

document.addEventListener('click', handleClickOutside, true);
//  To handle the text within textbox
const setSelectedValue = (e) => {
	const selectCount = e.target.parentNode.querySelectorAll('.section.allgift-section .dropdown__list-item.selected');
	const defaultDropdownValue = e.target.parentNode.parentNode.querySelector('.giftcategory-alltext');
	const defaultvalue = defaultDropdownValue.dataset.defaultdropdowntext;
	const changedDropdownValue = e.target.parentNode.parentNode.parentNode.querySelector('.multiselect__dropdown').dataset.filtertext;
	if (selectCount.length === 1) {
		defaultDropdownValue.innerHTML = [...selectCount].map((selectedOption) => {
			return selectedOption?.innerText;
		});
	} else if (selectCount.length > 1) {
		defaultDropdownValue.innerHTML = changedDropdownValue + ' (' + selectCount.length + ')';
	} else {
		defaultDropdownValue.innerHTML = defaultvalue;
	}
};

//  To select and deselect dropdown items
const selecthandler = (e) => {
	if (!e.target.parentNode.classList.contains('rt-Country')) {
		if (e.target.classList.contains('multiselect__dropdown--option') || e.target.parentNode.classList.contains('multiselect__dropdown--option')) {
			const defaultFirstValue = e.target.parentNode.parentNode.querySelector('.giftcategory-alltext');
			if (defaultFirstValue) {
				const defaultvalue = defaultFirstValue.dataset.defaultdropdowntext;
				const multiselectList = document.querySelectorAll('.multiselect__dropdown__selected');
				if (e.target.childNodes.length > 0) {
					//  Uncheck tick mark
					if (e.target.querySelector('.section.allgift-section .select-option__icon').style.display === 'block') {
						e.target.querySelector('.section.allgift-section .dropdown__list-item').classList.remove('selected');
						e.target.querySelector('.section.allgift-section .select-option__icon').style.display = 'none';
						setSelectedValue(e);
						//  Add tick mark
					} else {
						//  If "All" option is selected, uncheck other values
						if (e.target.innerText === defaultvalue) {
							multiselectList.forEach((item, index, arr) => {
								if (e.target.parentNode.parentNode === multiselectList[index].parentNode) {
									const allicons = multiselectList[index].parentNode.querySelectorAll('.select-option__icon');
									allicons.forEach((item, index, arr) => { allicons[index].style.display = 'none'; });
									const allDropdownItem = multiselectList[index].parentNode.querySelectorAll('.dropdown__list-item');
									allDropdownItem.forEach((item, index, arr) => { allDropdownItem[index].classList.remove('selected'); });
								}
							});
						} else {
							//  Deselect "All" option, if other option is selected.
							multiselectList.forEach((item, index, arr) => {
								if (e.target.parentNode.parentNode === multiselectList[index].parentNode && multiselectList[index].parentNode.querySelector('.dropdown__list-item').innerText === defaultvalue) {
									multiselectList[index].parentNode.querySelector('.dropdown__list-item').classList.remove('selected');
									multiselectList[index].parentNode.querySelector('.select-option__icon').style.display = 'none';
								}
							});
						}
						e.target.querySelector('.section.allgift-section .select-option__icon').style.display = 'block';
						e.target.querySelector('.section.allgift-section .dropdown__list-item').classList.toggle('selected');
						setSelectedValue(e);
					}
				}
			}
		}
	}
};

document.addEventListener('click', selecthandler, true);
