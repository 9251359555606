const infiniteScrollFeed = document.querySelector('.feed');

if (infiniteScrollFeed != null) {
	const feedEl = document.querySelector('.feed');
	const loaderEl = document.querySelector('.loader');

	// get the feed from API
	const getQuotes = async(page, limit) => {
		const API_URL = `https://api.javascripttutorial.net/v1/quotes/?page=${page}&limit=${limit}`;
		/*eslint-disable */
		const response = await fetch(API_URL);
		/* eslint-enable */
		// handle 404
		if (!response.ok) {
			throw new Error(`An error occurred: ${response.status}`);
		}
		return await response.json();
	};

	// show the feed
	const showFeedItems = (feed) => {
		feed.forEach(feedItem => {
			const feedItemEl = document.createElement('div');
			feedItemEl.classList.add('portal-section');

			feedItemEl.innerHTML = `
				<div class="portal-tile portal-tile--vertical">
					<div class="portal-tile__primary">
						<div class="image">
							<img class="image__src" src="/assets/img/test-child.jpg" alt="Child name">
						</div>
					</div>
					<div class="portal-tile__secondary">
						<span class="portal-tile__tag">August ${feedItem.id}, 2021</span>
						<span class="portal-tile__title portal-tile__title--lg">${feedItem.author}</span>
						<span class="portal-tile__subline">${feedItem.quote}</span>
						<a class="link link--primary link--suffix-icon" aria-label="Link" href="/">
							Read more
							<img src="/assets/icons/blue/chevron-right-dark.svg" class="ml-1 before-hover" />
							<img src="/assets/icons/blue/chevron-right.svg" class="ml-1 after-hover" />
						</a>
					</div>
				</div>`;

			feedEl.appendChild(feedItemEl);
		});
	};

	const hideLoader = () => {
		loaderEl.classList.remove('show');
	};

	const showLoader = () => {
		loaderEl.classList.add('show');
	};

	const hasMoreQuotes = (page, limit, total) => {
		const startIndex = (page - 1) * limit + 1;
		return total === 0 || startIndex < total;
	};

	// load feed
	const loadQuotes = async(page, limit) => {
		// show the loader
		showLoader();

		// 0.1 second later
		setTimeout(async() => {
			try {
				// if having more feed to fetch
				if (hasMoreQuotes(page, limit, total)) {
					// call the API to get feed
					const response = await getQuotes(page, limit);
					// show feed
					showFeedItems(response.data);
					// update the total
					total = response.total;
				}
			} catch (error) {
				console.log(error.message);
			} finally {
				hideLoader();
			}
		}, 500);
	};

	// control variables
	let currentPage = 1;
	const limit = 5;
	let total = 0;

	window.addEventListener('scroll', () => {
		const {
			scrollTop,
			scrollHeight,
			clientHeight
		} = document.documentElement;

		if (scrollTop + clientHeight >= scrollHeight - 5 &&
			hasMoreQuotes(currentPage, limit, total)) {
			currentPage++;
			loadQuotes(currentPage, limit);
		}
	}, {
		passive: true
	});

	// initialize
	loadQuotes(currentPage, limit);
}
